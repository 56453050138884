import validate from "/vercel/path0/node_modules/nuxt/dist/pages/runtime/validate.js";
import manifest_45route_45rule from "/vercel/path0/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  manifest_45route_45rule
]
export const namedMiddleware = {
  auth: () => import("/vercel/path0/middleware/auth.ts"),
  calculator: () => import("/vercel/path0/middleware/calculator.ts"),
  subscribed: () => import("/vercel/path0/middleware/subscribed.ts")
}